import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { HorizonRepo } from './/HorizonRepoExtension';
import { HorizonRepoSchema } from './schema';

export const HorizonRepoFieldExtension = scaffolderPlugin.provide(
    createScaffolderFieldExtension({
        name: 'HorizonRepo',
        component: HorizonRepo,
        schema: HorizonRepoSchema
    }),
);