import React, { useState } from 'react';
import { FieldValidation, } from '@rjsf/core';
import FormControl from '@material-ui/core/FormControl';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { InputLabel, Input, FormHelperText, capitalize } from '@material-ui/core';
/*
 This is the actual component that will get rendered in the form
*/
export const HorizonRepo = (props: FieldExtensionComponentProps<string[]>) => {
  const { formData, required, rawErrors, formContext } = props
  const [inputValue, setInputValue] = useState<HorizonOutput>();
  console.log(formContext)
  const buildRepoName = (e: any, input: HorizonRepoInput) => {
    console.log(e)
    const { system, name } = input
    if (!system || !name) { return '' }
    let project = system.split("/").slice(-1)[0]
    return setInputValue({ name: `${capitalize(project)}.${name}` })

  }
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <InputLabel htmlFor="validateName">Name</InputLabel>
      <Input
        id="validateName"
        aria-describedby="entityName"
        value={inputValue}
        onChange={e => buildRepoName(e.target.value, formContext.formData)}
      />
      <FormHelperText id="entityName">
        Use only letters, numbers, hyphens and underscores
      </FormHelperText>
    </FormControl>
  );
};

interface HorizonRepoInput {
  system: string
  name: string
}

interface HorizonOutput {
  name: string
}

export const horizonRepoValidation = (
  value: string,
  validation: FieldValidation,
) => {
  const kebabCase = /^[a-z0-9-_]+$/g.test(value);

  if (kebabCase === false) {
    validation.addError(
      `Only use letters, numbers, hyphen ("-") and underscore ("_").`,
    );
  }
};


/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// import React, { useState } from 'react';
// import useAsync from 'react-use/lib/useAsync';
// import useEffectOnce from 'react-use/lib/useEffectOnce';
// import { GetEntityFacetsRequest } from '@backstage/catalog-client';
// import { makeValidator } from '@backstage/catalog-model';
// import { useApi } from '@backstage/core-plugin-api';
// import { catalogApiRef } from '@backstage/plugin-catalog-react';
// import { FormControl, TextField } from '@material-ui/core';
// import { Autocomplete } from '@material-ui/lab';
// import { EntityTagsPickerProps } from './schema';

// export { EntityTagsPickerSchema } from './schema';

// /**
//  * The underlying component that is rendered in the form for the `EntityTagsPicker`
//  * field extension.
//  *
//  * @public
//  */
// export const EntityTagsPicker = (props: EntityTagsPickerProps) => {
//   const { formData, onChange, uiSchema } = props;
//   const catalogApi = useApi(catalogApiRef);
//   const [tagOptions, setTagOptions] = useState<string[]>([]);
//   const [inputValue, setInputValue] = useState('');
//   const [inputError, setInputError] = useState(false);
//   const tagValidator = makeValidator().isValidTag;
//   const kinds = uiSchema['ui:options']?.kinds;
//   const showCounts = uiSchema['ui:options']?.showCounts;
//   const helperText = uiSchema['ui:options']?.helperText;

//   const { loading, value: existingTags } = useAsync(async () => {
//     const facet = 'metadata.tags';
//     const tagsRequest: GetEntityFacetsRequest = { facets: [facet] };
//     if (kinds) {
//       tagsRequest.filter = { kind: kinds };
//     }

//     const { facets } = await catalogApi.getEntityFacets(tagsRequest);

//     const tagFacets = Object.fromEntries(
//       facets[facet].map(({ value, count }) => [value, count]),
//     );

//     setTagOptions(
//       Object.keys(tagFacets).sort((a, b) =>
//         showCounts ? tagFacets[b] - tagFacets[a] : a.localeCompare(b),
//       ),
//     );

//     return tagFacets;
//   });

//   const setTags = (_: React.ChangeEvent<{}>, values: string[] | null) => {
//     // Reset error state in case all tags were removed
//     let hasError = false;
//     let addDuplicate = false;
//     const currentTags = formData || [];

//     // If adding a new tag
//     if (values?.length && currentTags.length < values.length) {
//       const newTag = (values[values.length - 1] = values[values.length - 1]
//         .toLocaleLowerCase('en-US')
//         .trim());
//       hasError = !tagValidator(newTag);
//       addDuplicate = currentTags.indexOf(newTag) !== -1;
//     }

//     setInputError(hasError);
//     setInputValue(!hasError ? '' : inputValue);
//     if (!hasError && !addDuplicate) {
//       onChange(values || []);
//     }
//   };

//   // Initialize field to always return an array
//   useEffectOnce(() => onChange(formData || []));

//   return (
//     <FormControl margin="normal">
//       <Autocomplete
//         multiple
//         freeSolo
//         filterSelectedOptions
//         onChange={setTags}
//         value={formData || []}
//         inputValue={inputValue}
//         loading={loading}
//         options={tagOptions}
//         ChipProps={{ size: 'small' }}
//         renderOption={option =>
//           showCounts ? `${option} (${existingTags?.[option]})` : option
//         }
//         renderInput={params => (
//           <TextField
//             {...params}
//             label="Tags"
//             onChange={e => setInputValue(e.target.value)}
//             error={inputError}
//             helperText={
//               helperText ??
//               "Add any relevant tags, hit 'Enter' to add new tags. Valid format: [a-z0-9+#] separated by [-], at most 63 characters"
//             }
//           />
//         )}
//       />
//     </FormControl>
//   );
// };
