import React from 'react';
import { FieldProps, FieldValidation } from '@rjsf/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
/*
 This is the actual component that will get rendered in the form
*/
export const KubernetesNaming = ({
    onChange,
    rawErrors,
    required,
    formData,
}: FieldProps<string>) => {
    return (
        <FormControl
            margin="normal"
            required={required}
            error={rawErrors?.length > 0 && !formData}
        >
            <InputLabel htmlFor="validateName">Name</InputLabel>
            <Input
                id="validateName"
                aria-describedby="entityName"
                onChange={e => onChange(e.target?.value)}
            />
            <FormHelperText id="entityName">
                Use only letters, numbers, hyphens and underscores
            </FormHelperText>
        </FormControl>
    );
};

/*
 This is a validation function that will run when the form is submitted.
  You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\
*/

export const KubernetesNamingValidation = (
    value: string,
    validation: FieldValidation,
) => {
    const validationString = /^[a-z]+?([a-z0-9-]+[a-z0-9])?$/g

    if (validationString.test(value) === false || value.length > 63) {
        validation.addError(
            `Only use lowercase letters, numbers, and hyphen ("-"). Must contain at most 63 characters. Validation is '${validationString}' `,
        );
    }
};